<template>
    <el-header class="header-box flex flex-space-between flex-align-center">
        <!--  logo  -->
        <div><img src="@/assets/imgs/logo.png"></div>
        <div class="menu-lists flex flex-align-center">
            <ul class="flex flex-align-center">
                <li
                    class="cursor-pointer"
                    :class="{checked: item.includePah.includes(selectPath)}"
                    v-for="(item,i) in list"
                    @click="handleRouter(item)" :key="i">{{ item.name }}
                </li>
            </ul>
            <div class="xcx-box cursor-pointer" @click="handleWx()">微信小程序 <i class="el-icon-arrow-down"></i></div>
        </div>

        <!--   微信小程序的弹窗     -->
        <Dialog :dialog-visible.sync="dialog" title="微信小程序"  :show-submit-btn="false">
            <img class="img-box" src="@/assets/imgs/home.png">
        </Dialog>

    </el-header>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'

import { menuLists } from '@/util/const'
import { useRoute, useRouter } from 'vue-router/composables'
import QRcode from 'qrcodejs2'
import Dialog from "@/components/dialog.vue";


const route = useRoute()
const router = useRouter()


let selectPath = ref(null)
const list = menuLists
let dialog = ref(false)
let code = null
const handleRouter = (item) => {
    selectPath.value = item.path
    router.push(item.path)
}

// 打开小程序二维码
const handleWx =()=>{
    dialog.value = true
    // if(code) return
    // setTimeout(()=>{
    //     code = new QRcode('qrCode', {
    //         width: 200, // 生成二维码宽度
    //         height: 200, // 生成二维码高度
    //         text: 'url地址', // 二维码地址或文本，如果是网址扫码后会跳转网址
    //         colorDark: '#000',
    //         colorLight: '#fff'
    //     })
    // },10)
}

watch(()=> route.path,val=>{
    selectPath.value = val
})


onMounted(() => {
    selectPath.value = route.path

})

</script>

<style lang="scss" scoped>
.header-box {
    height: 109px !important;
    background: #070707;
    position: relative;
    z-index: 9;

    img {
        width: 380.11px;
        height: 48.27px;
    }

    .menu-lists {
        li {
            width: 85px;
            height: 100%;
            text-align: center;
            line-height: 109px;
            color: #efe0e2;
            font-size: 20px;

            &:hover {
                background: #8b3527;
            }

            &.checked {
                background: #8b3527;
            }

        }
    }

    .xcx-box {
        color: #ffffff;
        font-size: 16px;
        width: 147px;
        height: 47px;
        border: 1px solid rgba(255, 255, 255, .3);
        border-radius: 24px;
        text-align: center;
        line-height: 45px;
        box-sizing: border-box;
        margin-left: 30px;

        i {
            margin-left: 5px;
        }
    }
}
.img-box{
    width: 300px;
    height: 300px;
}
</style>
