import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // 其它页面使用按需加载的形式
    {
        path: '/introduction',
        name: 'Introduction',
        component: () => import(/* webpackChunkName: "introduction" */ '../views/introduction.vue')
    },
    {
        path: '/exhibition',
        name: 'Exhibition',
        component: () => import(/* webpackChunkName: "exhibition" */ '../views/exhibition.vue')
    },{
        path: '/exhibition-detail',
        name: 'ExhibitionDetail',
        meta:{
            title:'展馆展览详情',
            menuPath:"/exhibition"
        },
        component: () => import(/* webpackChunkName: "exhibition" */ '../views/exhibitionDetail.vue')
    }, {
        path: '/information',
        name: 'Information',
        meta:{
          title:'资讯'
        },
        component: () => import(/* webpackChunkName: "information" */ '../views/information.vue')
    },{
        path: '/information/detail',
        name: 'InformationDetail',
        meta:{
            title:'资讯详情',
            menuPath:"/information"
        },
        component: () => import(/* webpackChunkName: "informationDetail" */ '../views/informationDetail.vue')
    },
    {
        path: '/cultural-relics',
        name: 'CulturalRelics',
        component: () => import(/* webpackChunkName: "culturalRelics" */ '../views/culturalRelics.vue')
    },
    {
        path: '/cultural-relics/detail',
        name: 'CulturalRelicsDetail',
        meta:{
            menuPath:"/cultural-relics"
        },
        component: () => import(/* webpackChunkName: "culturalRelicsDetail" */ '../views/culturalRelicsDetail.vue')
    }, {
        path: '/cultural-creative',
        name: 'CulturalRelics',
        component: () => import(/* webpackChunkName: "culturalRelics" */ '../views/culturalCreative.vue')
    },
    {
        path: '/cultural-creative/detail',
        name: 'CulturalRelicsDetail',
        meta:{
            menuPath:"/cultural-creative"
        },
        component: () => import(/* webpackChunkName: "culturalRelicsDetail" */ '../views/culturalCreativeDetail.vue')
    }, {
        path: '/intangible-cultural',
        name: 'intangibleCultural',
        component: () => import(/* webpackChunkName: "culturalRelics" */ '../views/intangibleCultural.vue')
    },
    {
        path: '/intangible-cultural/detail',
        name: 'intangibleCulturalDetail',
        meta:{
            menuPath:"/cultural-creative"
        },
        component: () => import(/* webpackChunkName: "culturalRelicsDetail" */ '../views/intangibleCulturalDetail.vue')
    },
]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 如果有savedPosition，就用它，没有就会是{ x: 0, y: 0 }
        if (savedPosition) {
            return savedPosition;
        } else {
            // 如果不想保存位置，可以返回falsy值，比如false或者null
            return { x: 0, y: 0 };
        }
    }
})

export default router
