
<template>
    <div class="tc box">
        <p>扫描二维码关注松溪县博物馆微信公众号</p>
        <p>进行票务预约</p>
        <img src="@/assets/imgs/visited.png">
    </div>
</template>
<script setup>
import {onMounted} from "vue";
import QRcode from "qrcodejs2";

onMounted(()=>{
    setTimeout(()=>{
        // new QRcode('qrCodeVisit', {
        //     width: 200, // 生成二维码宽度
        //     height: 200, // 生成二维码高度
        //     text: '微信预约地址', // 二维码地址或文本，如果是网址扫码后会跳转网址
        //     colorDark: '#000',
        //     colorLight: '#fff'
        // })
    },10)
})

</script>
<style lang="scss" scoped>
.box{
    padding: 38px 0;

    p{
        color:#202020;
        font-size:30px;

    }
  ::v-deep  img{
       margin: 50px auto;
  }

    img{
        width: 300px;
        height: 300px;
    }
}
</style>
