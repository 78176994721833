<template>
  <div class="footer-wrap">
    <div class="fun-box flex flex-space-between">
      <div
        v-for="(item, i) in functionLists"
        :key="i"
        @click="handleFun(item, i)"
      >
        <img :src="require(`@/assets/imgs/ico${i + 1}.png`)" />
        <p>{{ item.name }}</p>
      </div>
    </div>
    <div class="tc footer-box">
      <!--  logo    -->
      <img src="@/assets/imgs/logo.png" />
      <el-divider class="line-color"></el-divider>
      <p class="footer-a cursor-pointer flex flex-space-between">
        <label
          v-for="(item, i) in menuLists"
          :key="i"
          class="cursor-pointer"
          @click="handleRouter(item)"
          >{{ item.name }}</label
        >
      </p>
      <div class="footer-remark">
        <p>Copyright © 2024 Museum.com 松溪县博物馆 版权所有</p>
        <p>闽ICP备2024075266号</p>
      </div>
    </div>

    <!--   弹窗   -->

    <Dialog
      :dialogVisible.sync="dialogVisibleTip"
      :title="componentTitle"
      :showSubmitBtn="false"
    >
      <component :is="componentName"   @isFormSubmitFun='handleDialog' ></component>
    </Dialog>
  </div>
</template>

<script setup>
import { menuLists } from "@/util/const";
import { useRoute, useRouter } from "vue-router/composables";
import { onMounted, reactive, ref, toRefs, defineAsyncComponent } from "vue";
import Dialog from "@/components/dialog.vue";
import VisitReservation from "@/components/visitReservation.vue";
import NoticeVisitors from "@/components/noticeVisitors.vue";
import MapText from "@/components/mapText.vue";
import ContactUs from "@/components/contactUs.vue";
import InteractiveMessage from "@/components/interactiveMessage.vue";

const router = useRouter();

const state = reactive({
  componentName: VisitReservation,
  componentTitle: "参观预约",
  dialogVisibleTip: false,
  functionLists: [
    {
      name: "参观预约",
      cName: VisitReservation,
    },
    {
      name: "参观须知",
      cName: NoticeVisitors,
    },
    {
      name: "交通地图",
      cName: MapText,
    },
    {
      name: "联系我们",
      cName: ContactUs,
    },
    {
      name: "互动留言",
      cName: InteractiveMessage,
    },
  ],
  isShowSubmitBtn: false,
  formData:false
});

const handleRouter = (item) => {
  router.push(item.path);
};

const handleFun = (item, i) => {
  state.componentTitle = state.functionLists[i].name;
  state.componentName = state.functionLists[i].cName;
  state.isShowSubmitBtn = item.name !== "互动留言";
  state.dialogVisibleTip = true;
};

const handleDialog=()=>{
  state.dialogVisibleTip = false
}



const { dialogVisibleTip, componentName, functionLists, componentTitle,isShowSubmitBtn,formData} = toRefs(state);
</script>

<style lang="scss" scoped>
.fun-box {
  padding: 80px 445px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.1);
  > div {
    color: #8b3527;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    img {
      width: 76px;
      height: 76px;
      margin-bottom: 10px;
      //background: #772e22;
    }
  }
}
.footer-box {
  padding: 28px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;

  img {
    width: 364px;
    height: 46px;
    margin-bottom: 8px;
  }
  .footer-a {
    width: 600px;
    font-size: 20px;
    margin: 18px auto 47px;
  }
  .footer-remark {
    font-size: 16px;
  }
  .line-color {
    background-color: rgba(#f5f5f5,.2);
  }
}
.footer-wrap {
  background: url("~@/assets/imgs/Group1166.png") no-repeat;
  background-size: 100% 100%;
}
</style>
