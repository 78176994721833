import instance from './axios'

export const getHomeBanner = (params = {}) => instance.get('/app-api/promotion/banner/list', { params })
export const articleLists = (params = {}) => instance.get('/app-api/promotion/article/page', {params} )
export const articleDetail = (params = {}) => instance.get('/app-api/promotion/article/get', {params} )


export const messageCreate = (params = {}) => instance.post('/app-api/museum/message/create', params )


export const spuLists = (params = {}) => instance.get('/app-api/product/spu/page', {params} )
export const spuDetail = (params = {}) => instance.get('/app-api/product/spu/get-detail', {params} )


export const classification = (params = {}) => instance.get('/app-api/museum/antique/classification', {params} )
export const antiqueLists = (params = {}) => instance.get('/app-api/museum/antique/page', {params} )
export const antiqueDetail = (params = {}) => instance.get('/app-api/museum/antique/get', {params} )
export const getHeritageLists = (params = {}) => instance.get('/app-api/museum/cultural-heritage/page', {params} )
export const getHeritageDetail = (params = {}) => instance.get('/app-api/museum/cultural-heritage/get?id='+params.id )

