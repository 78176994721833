export const menuLists = [
    {
        name: '首页',
        path: '/',
        includePah: ['', '/'],
    }, {
        name: '简介',
        path: '/introduction',
        includePah: ['/introduction'],
    }, {
        name: '展览',
        path: '/exhibition',
        includePah: ['/exhibition', '/exhibition-detail'],
    }, {
        name: '文物',
        path: '/cultural-relics',
        includePah: ['/cultural-relics', '/cultural-relics/detail'],
    }, {
        name: '资讯',
        path: '/information',
        includePah: ['/information', '/information/detail'],
    }, {
        name: '文创',
        path: '/cultural-creative',
        includePah: ['/cultural-creative', '/cultural-creative/detail'],
    },{
        name: '非遗',
        path: '/intangible-cultural',
        includePah: ['/intangible-cultural', '/intangible-cultural/detail'],
    },
]
