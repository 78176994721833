<script setup>

const props = defineProps({
    dialogVisible:{
        type:Boolean,
        default:false
    },
    showCancelBtn:{
        type:Boolean,
        default:true
    },cancelText:{
        type:String,
        default:'关闭'
    },
    showSubmitBtn:{
        type:Boolean,
        default:true
    },
    showFooter:{
        type:Boolean,
        default:true
    },
    title:{
        type:String,
        default:'标题'
    }
})

const emit = defineEmits(['update:dialogVisible']);

// 关闭方法
const handleClose=()=>{
    console.log('我在关闭');
    emit('update:dialogVisible', false);
}

// 确定
const handleSubmit =()=>{
    emit('handleSure')
}

</script>

<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        width="700px"
        :show-close="false"
        :center="true"
        :before-close="handleClose">
        <slot></slot>
        <span slot="footer" v-if="showFooter" class="dialog-footer">
            <el-button v-if="showSubmitBtn" class="submit-btn" type="primary" @click="handleSubmit()">提交</el-button>
            <el-button v-if="showCancelBtn" class="cancel-btn" @click="handleClose()">{{cancelText}}</el-button>
        </span>
    </el-dialog>
</template>

<style scoped lang="scss">
::v-deep .el-dialog__header{
    height:112px;
    background:#8b3527;
    box-sizing: border-box;
    padding: 0;
    line-height: 112px;
    width: 100%;

    .el-dialog__title{
        font-weight:700;
        font-size:47px;
        color: #ffffff;
        // line-height: 66px;

    }
}
.cancel-btn{
    width:209px;
    height:66px;
    border:1px solid #a8a8a8;
    text-align: center;
    color:#202020;
    font-size:30px;
    background:#efefef;
    box-sizing: border-box;
    line-height: 66px;
     padding: 0;
}
.submit-btn{
    width:209px;
    height:66px;
    background:#ffffff;
    border:1px solid #a8a8a8;
    box-sizing: border-box;
    color:#202020;
    font-size:30px;
     line-height: 66px;
     padding: 0;
}
::v-deep .el-dialog{
    background:#efefef;
}
</style>
