import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/assets/css/reset.scss'
import '../src/assets/css/common.scss'

Vue.use(ElementUI)
Vue.config.productionTip = false

const creatApp = new Vue({
  router,
  store,
  render: h => h(App)
})
router.onReady(() => creatApp.$mount('#app'))
